import revive_payload_client_uYl4JDutUH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pGBcaETrUd from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_F2LPGj3Nbr from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/app/plugins/router.js";
import supabase_client_w5AKLAmZH0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_8A3YyALGVJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qRWJglHE49 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_u9rIlfAkzB from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cSyvesIr9W from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_oh5okt2cwmvqweiq2yxrt3gkxu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_DT5ty1VGt6 from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.29.1_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2__wukhqh2shf7qxg2aka4ppbmqci/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_yMBQqCZUg2 from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_ters_4mnx24v2rp25yrfrejrcoypwxm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import fathom_client_HlKuHmwBFW from "/vercel/path0/plugins/fathom.client.ts";
import init_client_lHDJZN4lq2 from "/vercel/path0/plugins/init.client.ts";
export default [
  revive_payload_client_uYl4JDutUH,
  unhead_pGBcaETrUd,
  router_F2LPGj3Nbr,
  supabase_client_w5AKLAmZH0,
  payload_client_8A3YyALGVJ,
  navigation_repaint_client_qRWJglHE49,
  check_outdated_build_client_u9rIlfAkzB,
  chunk_reload_client_cSyvesIr9W,
  components_plugin_KR1HBZs4kY,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_DT5ty1VGt6,
  plugin_yMBQqCZUg2,
  fathom_client_HlKuHmwBFW,
  init_client_lHDJZN4lq2
]