import revive_payload_client_YuWdjjQE9o from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0ZRRcYkcbz from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lDE7xaOJt5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/app/plugins/router.js";
import supabase_client_4C8Erk5HUQ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_WwvUx872A1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5muCOP0Wjf from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_85Z9fx7tho from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9jD0jo2lSN from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_encoding@0.1.13_eslint@9.19._2w65q26vahgfa53uis7fre2jgi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_EgoaE1hp3R from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.29.1_vite@6.0.11_@types+node@22.10.10_jiti@2.4._z6c3l7qmntys2vk6e5vbzj6uiq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_uFAQOUAGSs from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_te_lamqird6yzqsjrqsavc5wocsoy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import fathom_client_HlKuHmwBFW from "/vercel/path0/plugins/fathom.client.ts";
import init_client_lHDJZN4lq2 from "/vercel/path0/plugins/init.client.ts";
export default [
  revive_payload_client_YuWdjjQE9o,
  unhead_0ZRRcYkcbz,
  router_lDE7xaOJt5,
  supabase_client_4C8Erk5HUQ,
  payload_client_WwvUx872A1,
  navigation_repaint_client_5muCOP0Wjf,
  check_outdated_build_client_85Z9fx7tho,
  chunk_reload_client_9jD0jo2lSN,
  components_plugin_KR1HBZs4kY,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_EgoaE1hp3R,
  plugin_uFAQOUAGSs,
  fathom_client_HlKuHmwBFW,
  init_client_lHDJZN4lq2
]